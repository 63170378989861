import { getOs } from '@/services/osDetector'
import { confirmEmail } from '@/services/auth'
import bus from '@/bus'

export default {
  props: ['token'],

  data() {
    return {
      os: null,
      loaded: false,
      error: false,
      deepLink: `f2clink://registration/${this.token}`
    }
  },

  metaInfo() {
    return {
      title: this.$t('auth.confirmEmail.pageTitle')
    }
  },

  created() {
    // this.os = getOs()
    //
    // if (this.mobileOs) {
    //   window.location.href = this.deepLink
    //   return
    // }

    this.confirmToken()
  },

  methods: {
    confirmToken() {
      let data = {
        "engine": "email",
        "confirmation_code": this.token
      }

      confirmEmail(data).then((response) => {
        this.loaded = true
      }).catch((error) => {
        this.loaded = true
        this.error = true
      })
    },

    login() {
      bus.$emit('loginRequested')
    },
  },

  computed: {
    mobileOs() {
      return this.os === 'iOS' || this.os === 'Android'
    }
  }
}